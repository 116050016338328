@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Neucha|Cabin+Sketch&display=swap");
/* Styles par défaut toute orientation et taille */
html, body {
  font-family: Neucha, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  width: 100%;
  height: 100%;
}

/* cookie consent box */
.dark.cc_dialog {
  background-color: #343a40;
  border-radius: 25px;
  font-family: Neucha, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.dark.cc_dialog h1.cc_dialog_headline {
  font-size: 1em;
}
.dark.cc_dialog button.cc_b_ok, .dark.cc_dialog button.cc_b_cp, .dark.cc_dialog p.cc_dialog_text {
  font-size: 0.75em;
}
.dark.cc_dialog button.cc_b_ok {
  color: #fff;
  background-color: #f00;
}

.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container {
  background: none;
}
.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_head {
  background-color: #343a40;
  border-radius: 25px 25px 0px 0px;
  font-family: Neucha, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_head .cc_cp_head_title p {
  font-size: 0.75em;
}
.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content {
  background-color: #343a40;
  font-family: Neucha, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content .cc_cp_m_menu li a {
  font-family: Neucha, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.75em;
}
.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content .cc_cp_m_menu li[active=true] {
  color: #fff;
  background-color: #f00 !important;
}
.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content .cc_cp_m_menu li[active=false] {
  color: #000;
  background-color: #fff;
}
.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer {
  background-color: #343a40;
  border-radius: 0px 0px 25px 25px;
  font-family: Neucha, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.dark.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer .cc_cp_f_save button {
  color: #fff;
  background-color: #f00;
  font-size: 0.75em;
}

a {
  color: red;
}
a:hover {
  color: red;
  font-weight: bold;
  text-decoration: none;
}

ul {
  padding: 0px;
  list-style-type: none;
}

th, td {
  text-align: center;
}

img, object, embed, canvas, video, audio, picture {
  max-width: 100%;
  height: auto;
}

.row, .divContainer {
  padding: 0px;
  margin: 0px;
  margin-left: -1px;
}

.form-group.row {
  margin: 10px;
}

h1 {
  text-align: center;
  font-size: 6vw;
  margin-bottom: 50px;
}

h2 {
  text-align: center;
  font-size: 5.5vw;
}

h3 {
  text-align: center;
  font-size: 5vw;
}

h4 {
  text-align: center;
  font-size: 4.5vw;
}

.container {
  width: 100%;
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  max-width: 100%;
  min-width: inherit;
}

form {
  text-align: center;
}

.topNav {
  width: 100%;
}
.topNav .login {
  padding: 0px;
}
.topNav .login .username {
  display: none;
  text-align: right;
  padding-right: 10px;
  height: 57px;
}
.topNav .login .imageLink {
  padding: 10px;
  text-align: right;
}
.topNav a, .topNav div.username {
  color: #FFFFFF80;
}
.topNav a span, .topNav div.username span {
  color: #FFFFFF80;
}
.topNav .logo {
  padding: 12px;
  border-radius: 0px 0px 0px 0px;
}
.topNav .logo .logoImage {
  margin: auto;
  width: 100px;
  height: 100px;
}
.topNav .topMenus {
  padding: 0px;
  height: 78px;
}
.topNav .topMenus .navbar-toggler-icon {
  width: 1em;
  height: 1em;
}
.topNav .topMenus .menusNavBar {
  padding: 6px;
}
.topNav .topMenus .navbarBottom {
  display: none;
}
.topNav .topMenus .menuText {
  display: inline-block;
  padding-left: 10px;
}
.topNav .topMenus .sousMenuText {
  display: inline-block;
  padding-left: 10px;
}
.topNav .topMenus .navbar-nav .dropdown-menu {
  position: absolute;
  top: -110px;
  left: 70px;
  float: none;
}
.topNav .elements {
  padding: 0px;
}
.topNav .search {
  padding-top: 10px;
  padding-left: 10px;
}
.topNav .search input {
  width: 175px;
  margin-right: 5px;
}
.topNav .triangle {
  margin-left: -0.5px;
  margin-top: -0.5px;
}
.topNav .triangle .shape {
  clip-path: polygon(0 0, 0 100%, 100% 0);
  height: 80px;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.footer {
  width: 100%;
  color: #FFFFFF80;
}
.footer a {
  color: #FFFFFF80;
}
.footer a span {
  color: #FFFFFF80;
}
.footer .copyright {
  padding: 0px;
  padding-top: 8px;
}
.footer .menuBottom {
  padding: 0px;
  padding-top: 8px;
}
.footer .menuBottom .navbarFooter {
  padding: 0px;
}
.footer .menuBottom .navbarFooter .navbar-nav {
  width: 100%;
  display: inline-block;
}
.footer .menuBottom .navbarFooter .navbar-nav .nav-item {
  display: inline-block;
}
.footer .menuBottom .navbarFooter .navbar-nav .nav-item .nav-link span {
  display: inline-block;
}
.footer .menuBottom .navbarFooter .navbar-nav .nav-item .nav-link .menuText {
  display: none;
}

.littleFormat {
  display: inline-block;
}
.littleFormat .infosLeft, .littleFormat .infosRight {
  margin-top: 30px;
}

.middleFormat {
  display: none;
}

.bigFormat {
  display: none;
}

.main {
  width: 100%;
}
.main .leftColumn {
  display: none;
}
.main .bodyContent {
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: inherit;
  text-align: justify;
  /* Articles */
  /* Mecaniques */
  /* Galeries */
  /* Recherche */
  /* Listes indexées */
  /* Personnes */
  /* Paragraphes des différentes pages de visualisation */
  /* Recherche filtrée */
  /* Derniers articles */
  /* Personnes */
  /* Dico ludique */
  /* Confirmation message */
  /* Page d'accueil */
}
.main .bodyContent .commentConnect {
  text-align: center;
}
.main .bodyContent .buttonBack {
  position: absolute;
  top: -4px;
  left: 0px;
}
.main .bodyContent .socialNetworks .shareButton {
  text-align: center;
}
.main .bodyContent .about .topPhoto {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
}
.main .bodyContent .about .topBloc {
  margin-top: 0px;
}
.main .bodyContent .about .motCle {
  font-weight: bold;
  font-style: italic;
  color: red;
}
.main .bodyContent .about .image {
  text-align: center;
  width: 200px;
  height: 200px;
  vertical-align: middle;
}
.main .bodyContent .about .image .fa {
  font-size: 6em;
}
.main .bodyContent .article .boite, .main .bodyContent .edition .boite, .main .bodyContent .gameevent .boite {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 45px;
}
.main .bodyContent .article .boite .photo, .main .bodyContent .edition .boite .photo, .main .bodyContent .gameevent .boite .photo {
  width: 200px;
  height: 200px;
  text-align: center;
  box-shadow: 10px 10px 10px grey;
  border-radius: 10px;
}
.main .bodyContent .article .colonneCentrale, .main .bodyContent .edition .colonneCentrale, .main .bodyContent .gameevent .colonneCentrale {
  margin-bottom: 20px;
  text-align: center;
}
.main .bodyContent .article .colonneCentrale .paragraphe, .main .bodyContent .edition .colonneCentrale .paragraphe, .main .bodyContent .gameevent .colonneCentrale .paragraphe {
  text-align: justify;
}
.main .bodyContent .article .colonneCentrale .introduction, .main .bodyContent .edition .colonneCentrale .introduction, .main .bodyContent .gameevent .colonneCentrale .introduction {
  font-style: italic;
  font-weight: bold;
  font-size: 4vw;
}
.main .bodyContent .article .colonneCentrale .introduction :before, .main .bodyContent .edition .colonneCentrale .introduction :before, .main .bodyContent .gameevent .colonneCentrale .introduction :before {
  content: '"';
}
.main .bodyContent .article .colonneCentrale .introduction :after, .main .bodyContent .edition .colonneCentrale .introduction :after, .main .bodyContent .gameevent .colonneCentrale .introduction :after {
  content: '"';
}
.main .bodyContent .article .colonneCentrale .introduction p, .main .bodyContent .edition .colonneCentrale .introduction p, .main .bodyContent .gameevent .colonneCentrale .introduction p {
  text-align: center;
}
.main .bodyContent .article .colonneCentrale .prerequisite ul, .main .bodyContent .edition .colonneCentrale .prerequisite ul, .main .bodyContent .gameevent .colonneCentrale .prerequisite ul {
  list-style-type: disc;
}
.main .bodyContent .article .colonneCentrale .liens, .main .bodyContent .edition .colonneCentrale .liens, .main .bodyContent .gameevent .colonneCentrale .liens {
  text-align: center;
}
.main .bodyContent .article .colonneCentrale .liens span.icone, .main .bodyContent .edition .colonneCentrale .liens span.icone, .main .bodyContent .gameevent .colonneCentrale .liens span.icone {
  margin-right: 10px;
}
.main .bodyContent .article .colonneCentrale .liens .oika .urlOika, .main .bodyContent .edition .colonneCentrale .liens .oika .urlOika, .main .bodyContent .gameevent .colonneCentrale .liens .oika .urlOika {
  text-align: center;
}
.main .bodyContent .article .colonneCentrale .socialNetworks, .main .bodyContent .edition .colonneCentrale .socialNetworks, .main .bodyContent .gameevent .colonneCentrale .socialNetworks {
  margin-top: 20px;
}
.main .bodyContent .article .colonneGauche, .main .bodyContent .article .colonneDroite, .main .bodyContent .article .basPage, .main .bodyContent .edition .colonneGauche, .main .bodyContent .edition .colonneDroite, .main .bodyContent .edition .basPage, .main .bodyContent .gameevent .colonneGauche, .main .bodyContent .gameevent .colonneDroite, .main .bodyContent .gameevent .basPage {
  display: none;
  text-align: center;
}
.main .bodyContent .article .infosLeft ul, .main .bodyContent .article .infosRight ul, .main .bodyContent .edition .infosLeft ul, .main .bodyContent .edition .infosRight ul, .main .bodyContent .gameevent .infosLeft ul, .main .bodyContent .gameevent .infosRight ul {
  padding: 0px;
  list-style-type: none;
}
.main .bodyContent .article .infosLeft ul li, .main .bodyContent .article .infosRight ul li, .main .bodyContent .edition .infosLeft ul li, .main .bodyContent .edition .infosRight ul li, .main .bodyContent .gameevent .infosLeft ul li, .main .bodyContent .gameevent .infosRight ul li {
  text-align: center;
}
.main .bodyContent .article .bigFormat .infosLeft, .main .bodyContent .article .bigFormat .infosRight, .main .bodyContent .edition .bigFormat .infosLeft, .main .bodyContent .edition .bigFormat .infosRight, .main .bodyContent .gameevent .bigFormat .infosLeft, .main .bodyContent .gameevent .bigFormat .infosRight {
  min-height: 500px;
}
.main .bodyContent .article .fullGallery, .main .bodyContent .edition .fullGallery, .main .bodyContent .gameevent .fullGallery {
  display: block;
}
.main .bodyContent .article h3, .main .bodyContent .edition h3, .main .bodyContent .gameevent h3 {
  margin-top: 10px;
}
.main .bodyContent .article .comments .blockUser, .main .bodyContent .edition .comments .blockUser, .main .bodyContent .gameevent .comments .blockUser {
  padding: 10px;
  margin-bottom: 20px;
}
.main .bodyContent .article .comments .blockUser .user, .main .bodyContent .edition .comments .blockUser .user, .main .bodyContent .gameevent .comments .blockUser .user {
  font-weight: bold;
  padding: 0px;
}
.main .bodyContent .article .comments .blockUser .publication, .main .bodyContent .edition .comments .blockUser .publication, .main .bodyContent .gameevent .comments .blockUser .publication {
  font-style: italic;
  padding: 0px;
}
.main .bodyContent .article .comments .content1, .main .bodyContent .edition .comments .content1, .main .bodyContent .gameevent .comments .content1 {
  background-color: white;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  margin-bottom: 20px;
  height: fit-content;
  box-shadow: 5px 5px 5px grey;
}
.main .bodyContent .article .comments .content0, .main .bodyContent .edition .comments .content0, .main .bodyContent .gameevent .comments .content0 {
  background-color: white;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  margin-bottom: 20px;
  height: fit-content;
  box-shadow: 5px 5px 5px grey;
}
.main .bodyContent .mechanic .children ul, .main .bodyContent .mechanic .boardgames ul {
  padding: 0px;
  list-style-type: none;
}
.main .bodyContent .mechanic .paragraphe {
  text-align: justify;
}
.main .bodyContent .imageVignette {
  max-width: 220px;
  max-height: 220px;
  min-width: 220px;
  min-height: 220px;
  margin: auto;
  margin-top: 40px;
  text-align: center;
}
.main .bodyContent .imageVignette span {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.main .bodyContent .imageVignette img {
  box-shadow: 10px 10px 10px grey;
  border-radius: 10px;
  margin: auto;
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.main .bodyContent .imageVignette img:hover {
  opacity: 0.4;
}
.main .bodyContent #zoomModal {
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.main .bodyContent #zoomModal #zoomModal-dialog {
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  transform: translate(0);
  max-width: 800px;
  width: 95%;
}
.main .bodyContent #zoomModal #zoomModal-dialog .modal-content {
  background-color: transparent;
  border: 0px;
}
.main .bodyContent #zoomModal #zoomModal-dialog .modal-content .modal-header {
  border: 0px;
  padding: 0px;
}
.main .bodyContent #zoomModal #zoomModal-dialog .modal-content .modal-header .close span {
  color: #FFFFFF;
}
.main .bodyContent #zoomModal #zoomModal-dialog .modal-content .modal-body {
  border: 0px;
  padding: 0px;
  margin: auto;
}
.main .bodyContent #zoomModal #zoomModal-dialog .modal-content .modal-body .imgZoom {
  border-radius: 20px;
}
.main .bodyContent .searchResults .term {
  margin-bottom: 20px;
}
.main .bodyContent .searchResults .category {
  text-align: left;
  margin-bottom: 20px;
}
.main .bodyContent .searchResults .category ul {
  list-style-type: none;
}
.main .bodyContent .searchResults .category h2 {
  text-align: left;
}
.main .bodyContent .letters .btn-group {
  margin: auto;
}
.main .bodyContent .selectFilter select {
  width: auto;
  margin: auto;
}
.main .bodyContent .listing {
  margin-top: 60px;
}
.main .bodyContent .listing .letterGroup {
  margin-bottom: 20px;
}
.main .bodyContent .listing .letterGroup ul {
  margin-left: 30px;
  margin-right: 30px;
  list-style-type: none;
}
.main .bodyContent .listing .letterGroup ul li {
  text-align: left;
}
.main .bodyContent .listing h3 {
  background-color: #343a40;
  color: white;
  margin-left: 30px;
  margin-right: 30px;
}
.main .bodyContent .listing h3 span {
  color: white;
}
.main .bodyContent .person ul {
  padding: 0px;
  list-style-type: none;
}
.main .bodyContent .person .element {
  margin-bottom: 20px;
  text-align: center;
}
.main .bodyContent .person .photo {
  width: 200px;
  height: 200px;
  text-align: center;
  box-shadow: 10px 10px 10px grey;
  border-radius: 10px;
}
.main .bodyContent .boardgame .element, .main .bodyContent .mechanic .element, .main .bodyContent .term .element, .main .bodyContent .context .element, .main .bodyContent .gameevent .element, .main .bodyContent .edition .element {
  margin-bottom: 20px;
  text-align: center;
}
.main .bodyContent .boardgame .element .photo, .main .bodyContent .mechanic .element .photo, .main .bodyContent .term .element .photo, .main .bodyContent .context .element .photo, .main .bodyContent .gameevent .element .photo, .main .bodyContent .edition .element .photo {
  width: 200px;
  height: 200px;
  text-align: center;
  box-shadow: 10px 10px 10px grey;
  border-radius: 10px;
}
.main .bodyContent .boardgame .element .poster, .main .bodyContent .mechanic .element .poster, .main .bodyContent .term .element .poster, .main .bodyContent .context .element .poster, .main .bodyContent .gameevent .element .poster, .main .bodyContent .edition .element .poster {
  box-shadow: 10px 10px 10px grey;
  border-radius: 10px;
  margin: auto;
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 200px;
}
.main .bodyContent .boardgame .element .poster:hover, .main .bodyContent .mechanic .element .poster:hover, .main .bodyContent .term .element .poster:hover, .main .bodyContent .context .element .poster:hover, .main .bodyContent .gameevent .element .poster:hover, .main .bodyContent .edition .element .poster:hover {
  opacity: 0.4;
}
.main .bodyContent .edition .modal-content {
  width: 100%;
}
.main .bodyContent .edition .description ul, .main .bodyContent .gameevent .description ul {
  list-style-type: disc;
}
.main .bodyContent .searchBoardgame .filtre {
  margin-bottom: 10px;
}
.main .bodyContent .searchBoardgame .filtre .titre {
  text-align: left;
  border-radius: 10px;
  padding: 5px;
}
.main .bodyContent .searchBoardgame .filtre .collapse {
  text-align: left;
}
.main .bodyContent .searchBoardgame .results {
  margin: auto;
}
.main .bodyContent .searchBoardgame .results td, .main .bodyContent .searchBoardgame .results th {
  padding: 10px;
}
.main .bodyContent .searchBoardgame .results td ul li, .main .bodyContent .searchBoardgame .results th ul li {
  text-align: left;
}
.main .bodyContent .searchBoardgame .results td .smallOika, .main .bodyContent .searchBoardgame .results th .smallOika {
  width: 30px;
  height: 30px;
}
.main .bodyContent .searchBoardgame .results td.left, .main .bodyContent .searchBoardgame .results th.left {
  text-align: left;
}
.main .bodyContent .searchBoardgame .results .full {
  display: none;
}
.main .bodyContent .lastArticles td.left, .main .bodyContent .lastArticles th.left {
  text-align: left;
}
.main .bodyContent .lastArticles .results {
  margin: auto;
}
.main .bodyContent .lastArticles .subscription {
  text-align: center;
  margin-bottom: 20px;
}
.main .bodyContent .about .photo {
  width: 200px;
  height: 200px;
  text-align: center;
  box-shadow: 10px 10px 10px grey;
  border-radius: 10px;
}
.main .bodyContent .about .photoBas {
  width: 500px;
  height: 232px;
}
.main .bodyContent .about .blocImage, .main .bodyContent .about .blocTexte, .main .bodyContent .about .topBloc {
  margin-top: 30px;
  margin-bottom: 30px;
}
.main .bodyContent .terms {
  text-align: center;
  margin-bottom: 20px;
}
.main .bodyContent .message {
  color: red;
  font-weight: bold;
  text-align: center;
}
.main .bodyContent .last-articles .fa,
.main .bodyContent .last-terms .fa,
.main .bodyContent .last-persons .fa {
  margin-right: 20px;
  margin-left: 20px;
}
.main .bodyContent .last-articles .header {
  clip-path: polygon(0 0, 0 102%, 100% 102%);
  height: 20px;
  width: 100%;
  background-color: #000000;
}
.main .bodyContent .last-articles table {
  border: 2px solid #000000;
  border-radius: 10% 10% 10% 10%;
}
.main .bodyContent .last-articles table td {
  background-color: rgba(0, 0, 0, 0.15);
  /* 0.5 - semi-transparent */
  text-align: left;
}
.main .bodyContent .last-articles table td a {
  color: #000000;
}
.main .bodyContent .last-articles table th {
  background-color: #000000;
  color: #FFFFFF;
}
.main .bodyContent .last-persons .header {
  height: 20px;
  width: 100%;
}
.main .bodyContent .last-persons table {
  border: 2px solid #ffffff;
}
.main .bodyContent .last-persons table td {
  background-color: rgba(255, 255, 255, 0.15);
  /* 0.5 - semi-transparent */
  text-align: center;
}
.main .bodyContent .last-persons table td a {
  color: #000000;
}
.main .bodyContent .last-persons table th {
  background-color: #ffffff;
}
.main .bodyContent .last-terms .header {
  clip-path: polygon(100% 0, 0 102%, 100% 102%);
  height: 20px;
  width: 100%;
  background-color: #dc3545;
}
.main .bodyContent .last-terms table {
  border: 2px solid #dc3545;
}
.main .bodyContent .last-terms table td {
  background-color: rgba(220, 53, 69, 0.15);
  /* 0.5 - semi-transparent */
  text-align: right;
}
.main .bodyContent .last-terms table td a {
  color: #000000;
}
.main .bodyContent .last-terms table th {
  background-color: #dc3545;
}

/* Styles par défaut spécifiques pour le format portrait */
@media all and (orientation: portrait) {
  html, body {
    font-size: 4vw;
  }

  h1 {
    font-size: 6vw;
  }

  h2 {
    font-size: 5.5vw;
  }

  h3 {
    font-size: 5vw;
  }

  h4 {
    font-size: 4.5vw;
  }

  .main {
    min-height: 670px;
  }
  .main .bodyContent {
    margin-top: 130px;
    margin-bottom: 60px;
  }
  .main .bodyContent .article .colonneCentrale .introduction {
    font-size: 4.5vw;
  }
  .main .bodyContent .buttonBack {
    top: -10px;
  }
}
/* Styles par défaut spécifiques pour le format paysage */
@media all and (orientation: landscape) {
  html, body {
    font-size: 2.5vw;
  }

  h1 {
    font-size: 4.5vw;
  }

  h2 {
    font-size: 4vw;
  }

  h3 {
    font-size: 3.5vw;
  }

  h4 {
    font-size: 3vw;
  }

  .topNav .search input {
    width: 290px;
  }
  .topNav .topMenus {
    height: fit-content;
  }
  .topNav .topMenus .menusNavBar {
    padding: 2px;
  }
  .topNav .login {
    height: fit-content;
  }
  .topNav .logo {
    padding: 9px;
  }
  .topNav .logo .logoImage {
    width: 80px;
    height: 80px;
  }

  .main {
    min-height: 350px;
  }
  .main .bodyContent {
    margin-top: 108px;
    margin-bottom: 60px;
  }
  .main .bodyContent .article .colonneCentrale .introduction {
    font-size: 3vw;
  }
  .main .bodyContent .buttonBack {
    top: -18px;
  }
}
@media (min-width: 576px) {
  .topNav .topMenus {
    height: 65px;
  }
  .topNav .topMenus .menuText {
    display: none;
  }
  .topNav .topMenus .menusNavBar {
    padding: 10px;
    font-size: 2vw;
  }
  .topNav .topMenus .navbarTop .nav-link {
    padding-right: 0px;
  }
  .topNav .topMenus .navbarBottom {
    display: none;
  }
  .topNav .login .loginBlock {
    height: 57px;
  }
  .topNav .login .loginBlock .loginButtons .loginButton, .topNav .login .loginBlock .loginButtons .registerButton, .topNav .login .loginBlock .loginButtons .logoutButton, .topNav .login .loginBlock .loginButtons .accountButton {
    top: -45px;
  }
}
@media (min-width: 710px) {
  .littleFormat {
    display: none;
  }

  .colonneCentrale .bigFormat .boite {
    display: none;
  }

  .middleFormat {
    display: inline-block;
  }

  .bigFormat {
    display: inline-block;
  }

  .topNav .logo {
    padding: 13px;
  }
  .topNav .logo .logoImage {
    width: 100px;
    height: 100px;
  }
  .topNav .topMenus .littleFormat {
    display: inline-block;
  }
  .topNav .topMenus .bigFormat {
    display: none;
  }
  .topNav .login .loginBlock {
    height: 57px;
  }
  .topNav .login .loginBlock .loginButtons .loginButton, .topNav .login .loginBlock .loginButtons .registerButton, .topNav .login .loginBlock .loginButtons .logoutButton, .topNav .login .loginBlock .loginButtons .accountButton {
    top: -45px;
  }
  .topNav .triangle .shape {
    clip-path: polygon(0 0, 0 100%, 100% 0);
    height: 20px;
  }

  .main {
    min-height: 1000px;
  }
  .main .leftColumn {
    display: block;
  }
  .main .bodyContent {
    margin-top: 142px;
    margin-bottom: 60px;
  }
}
@media (min-width: 768px) {
  html, body {
    font-size: 2vw;
  }

  h1 {
    font-size: 4vw;
  }

  h2 {
    font-size: 3.5vw;
  }

  h3 {
    font-size: 3vw;
  }

  h4 {
    font-size: 2.5vw;
  }

  .main .bodyContent .article .colonneCentrale .introduction {
    font-size: 2.5vw;
  }
  .main .bodyContent .buttonBack {
    top: -35px;
  }
  .main .bodyContent .about .blocTexte, .main .bodyContent .about .topBloc {
    margin: auto;
    vertical-align: middle;
  }
  .main .bodyContent .about .row {
    display: flex;
    flex-direction: row;
  }
  .main .bodyContent .about .inversed {
    flex-direction: row-reverse;
  }

  .topNav .logo {
    border-radius: 0px 0px 25px 0px;
    padding: 13px;
  }
  .topNav .logo .logoImage {
    width: 150px;
    height: 150px;
  }
  .topNav .login {
    padding: 0px;
  }
  .topNav .login .username {
    display: block;
  }
  .topNav .login .loginBlock {
    height: 65px;
  }
  .topNav .login .loginBlock .loginButtons .loginButton, .topNav .login .loginBlock .loginButtons .registerButton, .topNav .login .loginBlock .loginButtons .logoutButton, .topNav .login .loginBlock .loginButtons .accountButton {
    top: -100px;
  }
  .topNav .search input {
    width: 365px;
    margin-right: 5px;
  }
}
/* Format normal en paysage (LG) */
@media (min-width: 992px) {
  html, body {
    font-size: 2vw;
  }

  h1 {
    font-size: 4vw;
  }

  h2 {
    font-size: 3.5vw;
  }

  h3 {
    font-size: 3vw;
  }

  h4 {
    font-size: 2.5vw;
  }

  .topNav .logo {
    padding: 12px;
  }
  .topNav .search {
    margin-left: 210px;
  }
  .topNav .search input {
    width: 365px;
  }
  .topNav .login {
    padding: 0px;
  }
  .topNav .login .username {
    display: block;
  }
  .topNav .navbarTop {
    top: 5px;
    position: absolute;
  }
  .topNav .navbarBottom {
    top: 0px;
    position: absolute;
  }
  .topNav .triangle .shape {
    clip-path: polygon(0 0, 0 40%, 100% 0);
    height: 61px;
  }

  .main {
    min-height: 1380px;
  }
  .main .leftColumn {
    display: block;
  }
  .main .bodyContent {
    margin-top: 203px;
    margin-bottom: 60px;
    /* Articles */
    /* Listes indexées */
  }
  .main .bodyContent .article .colonneGauche, .main .bodyContent .article .colonneDroite, .main .bodyContent .article .basPage {
    display: none;
  }
  .main .bodyContent .article .fullGallery {
    display: block;
  }
  .main .bodyContent .article .colonneCentrale .introduction {
    font-size: 2.5vw;
  }
  .main .bodyContent .buttonBack {
    top: -30px;
  }
  .main .bodyContent .letters .btn-group.leftGroup {
    margin-right: 0px;
  }
  .main .bodyContent .letters .btn-group.rightGroup {
    margin-left: 0px;
  }
  .main .bodyContent .searchBoardgame .results .full {
    display: revert;
  }
}
/* Format large en paysage (XL) */
@media (min-width: 1050px) {
  html, body {
    font-size: 1.5vw;
  }

  h1 {
    font-size: 2.5vw;
  }

  h2 {
    font-size: 2.25vw;
  }

  h3 {
    font-size: 2vw;
  }

  h4 {
    font-size: 1.75vw;
  }

  .main {
    /* Articles */
  }
  .main .bodyContent .article .colonneCentrale .introduction {
    font-size: 1.75vw;
  }

  .topNav .topMenus .menuText {
    display: inline-block;
  }
  .topNav .topMenus .navbarTop {
    top: 0px;
    position: absolute;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .topNav .topMenus .navbarBottom {
    top: 40px;
    position: absolute;
    display: inline-block;
  }
  .topNav .topMenus .littleFormat {
    display: none;
  }
  .topNav .topMenus .bigFormat {
    display: inline-block;
  }
  .topNav .topMenus .menusNavBar {
    font-size: 1.25vw;
    padding: 0px;
  }
  .topNav .login .loginBlock {
    height: 75px;
  }

  .footer .menuBottom .navbarFooter .navbar-nav .nav-item .nav-link .menuText {
    display: inline-block;
  }
}
/* Format large en paysage (XL) */
@media (min-width: 1200px) {
  html, body {
    font-size: 1.25vw;
  }

  h1 {
    font-size: 2.25vw;
  }

  h2 {
    font-size: 2vw;
  }

  h3 {
    font-size: 1.75vw;
  }

  h4 {
    font-size: 1.5vw;
  }

  .topNav .login {
    padding: 0px;
  }
  .topNav .login .username {
    display: block;
  }
  .topNav .logo {
    padding: 13px;
  }
  .topNav .topMenus {
    height: 60px;
  }
  .topNav .triangle .shape {
    clip-path: polygon(0 0, 0 80%, 100% 0);
    height: 61px;
  }

  .main {
    /* Articles et éditions */
  }
  .main .leftColumn {
    display: block;
  }
  .main .bodyContent .article .colonneGauche, .main .bodyContent .article .colonneDroite, .main .bodyContent .article .basPage, .main .bodyContent .edition .colonneGauche, .main .bodyContent .edition .colonneDroite, .main .bodyContent .edition .basPage, .main .bodyContent .gameevent .colonneGauche, .main .bodyContent .gameevent .colonneDroite, .main .bodyContent .gameevent .basPage {
    display: block;
  }
  .main .bodyContent .article .fullGallery, .main .bodyContent .edition .fullGallery, .main .bodyContent .gameevent .fullGallery {
    display: none;
  }
  .main .bodyContent .article .colonneCentrale .introduction, .main .bodyContent .edition .colonneCentrale .introduction, .main .bodyContent .gameevent .colonneCentrale .introduction {
    font-size: 1.5vw;
  }
  .main .bodyContent .article .colonneCentrale .bigFormat .boite, .main .bodyContent .edition .colonneCentrale .bigFormat .boite, .main .bodyContent .gameevent .colonneCentrale .bigFormat .boite {
    display: inline-block;
  }
  .main .bodyContent .article .middleFormat, .main .bodyContent .edition .middleFormat, .main .bodyContent .gameevent .middleFormat {
    display: none;
  }
}
@media (min-width: 1360px) {
  html, body {
    font-size: 1.25vw;
  }

  h1 {
    font-size: 2.25vw;
  }

  h2 {
    font-size: 2vw;
  }

  h3 {
    font-size: 1.75vw;
  }

  h4 {
    font-size: 1.5vw;
  }

  .last-articles .fa,
.last-terms .fa,
.last-persons .fa {
    font-size: 1.75vw;
  }

  .topNav .logo {
    border-radius: 0px 0px 25px 0px;
    padding: 20px;
  }
  .topNav .logo .logoImage {
    width: 200px;
    height: 200px;
  }
  .topNav .triangle .shape {
    clip-path: polygon(0 0, 0 80%, 100% 0);
    height: 80px;
  }
  .topNav .topMenus {
    height: 80px;
  }
  .topNav .topMenus .navbarTop {
    top: -45px;
    position: absolute;
  }
  .topNav .topMenus .navbarBottom {
    top: 0px;
    position: absolute;
  }
  .topNav .search {
    margin-left: 540px;
  }

  .main .bodyContent {
    margin-top: 240px;
    margin-bottom: 60px;
  }
  .main .bodyContent .article .colonneCentrale .introduction, .main .bodyContent .edition .colonneCentrale .introduction, .main .bodyContent .gameevent .colonneCentrale .introduction {
    font-size: 1.5vw;
  }
  .main .bodyContent .buttonBack {
    top: -5px;
  }
}
@media (min-width: 1500px) {
  .topNav .search {
    margin-left: 650px;
  }
}
@media (min-width: 1700px) {
  .topNav .topMenus .navbarTop {
    top: -45px;
    position: absolute;
  }
  .topNav .topMenus .navbarBottom {
    top: 30px;
    position: absolute;
  }
  .topNav .search {
    margin-left: 650px;
  }
}
@media (min-width: 1800px) {
  .topNav .search {
    margin-left: 820px;
  }
}
@media (min-width: 2024px) {
  .topNav .search {
    margin-left: 1000px;
  }

  html, body {
    font-size: 1vw;
  }
}
@media (min-width: 2500px) {
  .topNav .topMenus .navbarTop {
    font-size: 0.75vw;
  }
  .topNav .topMenus .navbarBottom {
    font-size: 0.75vw;
  }
  .topNav .search {
    margin-left: 1000px;
  }

  html, body {
    font-size: 0.75vw;
  }
}